<template lang='pug'>
	div
		.d-flex.position-relative.my-textarea(:class="[{'is-focused': isFocused, 'is-float': value && !isFocused, 'is-error': errorFeedback != '' || isError}, classInput]")
			textarea.b3.w-100.my-textarea__input(
				autocomplete='off'
				@input="$emit('input', $event.target.value)"
				:value="value"
				:id="`textarea-${_uid}`"
				@focus="isFocused = true, $emit('focus')"
				@blur="isFocused = false, $emit('blur')"
				:maxlength="maxlength"
			)
			label.m-0.b3.my-textarea__label(:class="{'b4': isFocused || value}") {{ label }}
			icon-close.my-textarea__reset(v-if="value" @click.prevent="$emit('input', '')")
			span.my-textarea__resizer
		.text-danger.b4.mt-1(v-if="errorFeedback != ''" v-html='errorFeedback')
</template>

<script>
import IconClose from "@/components/Common/IconClose";

export default {
	name: 'MyInput',
	components: {
		IconClose
	},
	props: {
		label: {
			type: String,
			default: '',
		},
		errorFeedback: {
			type: String,
			default: '',
		},
		value: {
			default: null,
		},
		maxlength: {
			type: [String, Number],
			default: '1000000000000',
		},
		classInput: {
			default: '',
		},
		isError: {
			type: Boolean,
			default: false,
		}
	},
	data: () => ({
		isFocused: false
	})
}
</script>

<style lang='scss'>
@import '~@/assets/css/sass/_mixins.scss';

.my-textarea {
	padding-top: 36px;
	background-color: var(--grey-bg-color);
	border: 1.5px solid transparent;
	border-radius: var(--border-radius-rounded);
	transition: var(--animation-time-short);

	&:hover {
		background-color: transparent;
		border: 1.5px solid var(--button-border-color);

		.my-textarea__resizer {
			background-color: var(--foreground-color);
		}
	}

	&:focus {
		background-color: transparent;
		border: 1.5px solid var(--button-border-color);

		.my-textarea__resizer {
			background-color: var(--foreground-color);
		}
	}

	&.is-focused {
		border: 1.5px solid var(--button-border-color);
		background-color: var(--foreground-color);

		.my-textarea__input {
			color: var(--main-text-color);
		}

		&::before {
			background-color: var(--foreground-color);
		}

		.my-textarea__resizer {
			background-color: var(--foreground-color);
		}
	}

	&.is-float {
		background-color: var(--grey-bg-color);

		.my-textarea__input {
			color: var(--main-text-colorr);
		}

		&::before {
			background-color: var(--grey-bg-color);
		}

		.my-textarea__resizer {
			background-color: var(--grey-bg-color);
		}
	}

	&.is-error {
		background-color: transparent;
		border: 1px solid var(--red-bg-color);

		&:hover,
		&:focus {
			border: 1px solid var(--red-bg-color) !important;
		}

		.my-textarea__label {
			color: var(--red-bg-color);
		}
	}

	&__input {
		z-index: 1;
		align-items: center;
		height: 92px;
		padding: 0 24px 6px 24px;
		border: none;
		background-color: transparent;
		border-radius: var(--border-radius-rounded);
		transition: var(--animation-time-short);

		&:hover {
			background-color: transparent !important;
		}

		&:focus {
			background-color: transparent !important;
		}

		/* Скрываем scrollbar для IE, Edge и Firefox */
		scrollbar-color: transparent !important;

		/* Скрываем scrollbar для Chrome, Safari и Opera */
		&::-webkit-scrollbar {
			width: auto;
			background-color: transparent !important;
		}

		&::-webkit-scrollbar-track {
			background-color: transparent !important;
		}

		&::-webkit-scrollbar-thumb {
			background-color: transparent !important;
			border-radius: 0 !important;
		}

		&::-webkit-resizer {
			background-color: transparent !important;
			border: none !important;
		}

		&::-webkit-scrollbar-corner {
			background-color: transparent !important;
		}
	}

	&__resizer {
		position: absolute;
		z-index: 0;
		right: 0;
		bottom: 0;
		width: 23px;
		height: 23px;
		background-image: url('/assets/img/icons/resizer.svg');
		background-size: 16px 16px;
		background-position: center;
		background-repeat: no-repeat;
		background-color: transparent;
		border-radius: var(--border-radius-rounded);
		transition: var(--animation-time-short);
	}

	&__label {
		z-index: 1;
		position: absolute;
		top: 20px;
		left: 24px;
		color: var(--grey-text-color);
		cursor: text;
		transition: var(--animation-time-short);
	}

	&__reset {
		z-index: 1;
		position: absolute;
		top: 13px;
		right: 19px;
		transition: var(--animation-time-short);

		&:hover,
		&:active {
			opacity: 0.7;
		}
	}
}

.change-bot__textarea-info.my-textarea, .change-bot__textarea-desc.my-textarea {
	padding-top: 46px;
	background-color: var(--bg-back);

	&.is-focused {
		background-color: var(--foreground-color);
	}

	.my-textarea {
		&__input {
			padding: 0 19px 10px 19px;
			resize: none;
		}

		&__label {
			top: 20px;
			left: 19px;
			letter-spacing: 0.25px;
		}

		&__resizer {
			display: none;
		}
	}
}

.change-bot__textarea-info {
	.my-textarea {
		&__input {
			height: 53px;
			min-height: 53px;
		}
	}
}

.change-bot__textarea-desc.my-textarea {
	.my-textarea {
		&__input {
			height: 174px;
			min-height: 174px;

			@include respond-below(sm) {
				height: 209px;
				min-height: 209px;
			}
		}
	}
}

.add-promotion__textarea {
	padding-top: 0;

	.my-textarea {
		&__input {
			padding: 45px 18px 11px 18px;
			resize: none;
			min-height: 132px;
			height: 132px;
		}

		&__label {
			top: 18px;
			left: 19px;
			font-family: Golos;
			letter-spacing: 0.25px;
		}

		&__resizer {
			display: none;
		}
	}

	&.is-error {
		border-color: var(--brand-error) !important;

		&:hover {
			border-color: var(--brand-error) !important;
		}

		.my-textarea {
			&__label {
				color: var(--grayscale-color-grey);
			}
		}
	}
}
</style>
